import { useSelector } from 'storage';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FormikContextType, useFormik } from 'formik';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import AutocompleteMemorized from 'components/ui/forms/AutocompleteMemorized';
import useLocales from 'hooks/useLocales';
import FormProvider from 'components/ui/forms/FormProvider';
import { Button, Stack, TableCell, TableRow, TextField, Typography } from '@mui/material';
import useFilterDataInitialize from 'hooks/useFilterDataInitialize';
import MODEL_NAME from 'typings/models/_model.enum';
import { ORDER_DEFAULT_TYPE } from 'typings/models/order/orderType.enum';
import DynamicFieldEditState from 'components/features/dynamicField/DynamicFieldEditState';
import DynamicFieldUtils from 'utils/models/subEntities/DynamicFieldUtils';
import useDI from 'hooks/useDI';
import OrderSessionContractDataTableBlock from 'components/_dashboardPagesFeatures/order/add/session/OrderSessionContractDataTableBlock';
import { LoadingButton } from '@mui/lab';
import { OrderContractSessionDataRequestDTO } from 'typings/dto/order';
import PopupAction from 'components/ui/popups/PopupAction';
import OrderTablesListBlock from 'components/_dashboardPagesFeatures/order/info/OrderTablesListBlock';
import { OrderRegionIcon } from '../../../../ui/icons';
import { useLocation } from 'react-router';
import TableVariant1 from 'components/ui/tables/TableVariant1';
import useDebounce from 'hooks/useDebounce';

type Props = {
  orderSession: OrderContractSessionData | null;
  enterprise: Enterprise;
  cancelHandler: VoidFunction;
  submitHandler: VoidFunction;
  cancelSessionHandler: VoidFunction;
  linkedOrderId: string | null;
  parentLinkedOrder?: Order;
  isLinked?: boolean;
};

function OrderAddMultipleFromSessionForm({
  orderSession,
  enterprise,
  submitHandler,
  cancelSessionHandler,
  linkedOrderId,
  parentLinkedOrder,
  isLinked,
}: Props) {
  const { currentUser } = useSelector((state) => state.auth);
  const { services, mappers, storageActions } = useDI();
  const { legalEntityAll, legalEntityAllAreLoading } = useSelector((state) => state.legalEntity);
  const { orderTypeAll, orderTypeAllAreLoading } = useSelector((state) => state.orderType);
  const { brandAll, brandAllAreLoading } = useSelector((state) => state.brand);
  const { shopAll, shopAllAreLoading } = useSelector((state) => state.shop);
  const { regionAll, regionAllAreLoading } = useSelector((state) => state.region);
  const handleFormErrors = useSnackbarErrorHandler();
  const { translate } = useLocales();
  const location = useLocation();
  const [isCancelBlockOpened, setIsCancelBlockOpened] = useState(false);
  const [contractsNumber, setContractsNumber] = useState<number | null>(orderSession?.apartments.length || null)

  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];


  const [validationSchema, initialValues, dynamicFieldsConfigs] = useMemo(() => {
    const dynamicFieldsConfigs = enterprise.settings.order.customFieldsSettings.customFields.map((i) => i.dynamicField);
    const dynamicFields = orderSession
      ? Object.values(orderSession.customFields).map((i) => i.dynamicField)
      : parentLinkedOrder
        ? Object.values(parentLinkedOrder.customFields).map((i) => i.dynamicField)
        : [];

    const fieldIsRequiredText = translate('errors.fieldIsRequired');
    const validationSchema = Yup.object().shape({
      type: Yup.mixed().required(fieldIsRequiredText),
      legalEntity: Yup.mixed().required(fieldIsRequiredText),
      region: Yup.mixed().required(fieldIsRequiredText),
      brand: Yup.mixed().required(fieldIsRequiredText),
      shop: Yup.mixed().required(fieldIsRequiredText),
      ...DynamicFieldUtils.getValidationAddFormSubScheme(enterprise.settings.order.customFieldsSettings.customFields, services.language),
    });

    const initialValues = orderSession
      ? {
        type: orderSession.type || null,
        legalEntity: orderSession.legalEntity || null,
        region: orderSession.region || parentLinkedOrder?.region || null,
        brand: orderSession.brand || null,
        shop: orderSession.shop || null,
        apartments: orderSession.apartments || [],
        // contractsNumber: orderSession.apartments.length || null
        ...DynamicFieldUtils.getInitialState(dynamicFields, dynamicFieldsConfigs),
      }
      : parentLinkedOrder
        ? {
          type: parentLinkedOrder.type || null,
          legalEntity: parentLinkedOrder.legalEntity || null,
          region: parentLinkedOrder?.region || null,
          brand: parentLinkedOrder.brand || null,
          shop: parentLinkedOrder.shop || null,
          apartments: [],
          // contractsNumber: null,
          ...DynamicFieldUtils.getInitialState(dynamicFields, dynamicFieldsConfigs),
        }
        : {
          type: null,
          legalEntity: null,
          region: null,
          brand: null,
          shop: null,
          apartments: [],
          // contractsNumber: null,
          ...DynamicFieldUtils.getInitialState(dynamicFieldsConfigs),
        };
    return [validationSchema, initialValues, dynamicFieldsConfigs];
  }, [orderSession?.id]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const { type, shop, brand, legalEntity, region } = values;

        if (!type || !legalEntity || !brand || !region || !shop) {
          throw new Error('Data is missing');
        }

        if (values.apartments.some(apartment => !apartment.apartmentNumber.length || !apartment.developerApartmentId.length)) {
          throw new Error(translate('errors.emptyApartmentData'))
        }

        const dynamicFieldsDTORequests = dynamicFieldsConfigs.map((i) => mappers.subEntities.dynamicField.editStateToRequestDTO(i, values));
        const dynamicFieldsDTOs = await Promise.all(dynamicFieldsDTORequests);

        const dto: OrderContractSessionDataRequestDTO = {
          orderTypeId: type.id,
          legalEntityId: legalEntity.id,
          customFields: {},
          brandId: brand.id,
          regionId: region.id,
          shopId: shop.id,
          shopNumber: shop.shopNumber,
          dealerNumber: shop.dealerNumber,
          apartments: values.apartments,
          linkedOrderId: linkedOrderId ? linkedOrderId : orderSession ? orderSession.linkedOrderId : undefined,
        };

        dynamicFieldsDTOs.forEach((i) => (dto.customFields[i.technicalName] = i.value));
        if (orderSession) {
          await storageActions.models.orderSession.patch({ ...dto, id: orderSession.id });
          await storageActions.models.orderSession.getOrderSession(orderSession.id);
        } else {
          const id = await storageActions.models.orderSession.createSession({ ...dto });
          await storageActions.models.orderSession.getOrderSession(id);
        }

        submitHandler();
      } catch (error) {
        handleFormErrors({ error, callback: () => setSubmitting(false) });
      }
    },
  });

  useDebounce({
    delay: 300,
    dependencies: [contractsNumber],
    action: () => {
      if (Number(contractsNumber) <= 0) return
      if (Number(contractsNumber) === formState.values.apartments?.length) return
      if (Number(contractsNumber) < formState.values.apartments?.length) {
        formState.setFieldValue('apartments', formState.values.apartments.slice(0, Number(contractsNumber)))
      } else {
        const apartmentsToAdd: OrderApartment[] = []
        for (let i = Number(contractsNumber); i > formState.values.apartments?.length; i--) {
          apartmentsToAdd.push({
            apartmentNumber: '',
            developerApartmentId: ''
          })
        }
        formState.setFieldValue('apartments', [...formState.values.apartments, ...apartmentsToAdd])
      }
    }
  })

  const tenantId = currentUser?.tenant.id || '';

  useFilterDataInitialize({
    [MODEL_NAME.orderType]: { tenantId },
    [MODEL_NAME.region]: { tenantId },
    [MODEL_NAME.legalEntity]: { tenantId },
    [MODEL_NAME.brand]: { tenantId },
    [MODEL_NAME.shop]: { tenantId },
  });

  // В форме создания основного заказа нужно убрать тип "Рекламация", так как рекламацию можно создать только если есть родительский
  const allowedOrderType = useMemo(() => {
    if (parentLinkedOrder && !isLinked) {
      if (lastSegment === ORDER_DEFAULT_TYPE.warranty) {
        formState.setFieldValue(
          'type',
          orderTypeAll.find((type) => type.technicalName === ORDER_DEFAULT_TYPE.warranty)
        );
      } else {
        formState.setFieldValue(
          'type',
          orderTypeAll.find((type) => type.technicalName === ORDER_DEFAULT_TYPE.complaint)
        );
      }
      return orderTypeAll;
    }
    return orderTypeAll.filter((type) => type.technicalName !== ORDER_DEFAULT_TYPE.complaint);
  }, [orderTypeAllAreLoading]);

  const shownCustomFieldIds = useMemo(() => {
    return enterprise.settings.order.customFieldsSettings.customFields
      .filter((i) => (formState.values.type ? i.showInSessionByOrderType[formState.values.type.technicalName] : false))
      .map((i) => i.dynamicField.id);
  }, [formState.values.type]);

  const isWarrantyWithParent = formState.values.type?.technicalName === ORDER_DEFAULT_TYPE.warranty && !!parentLinkedOrder;

  const disableType = formState.values.type?.technicalName === ORDER_DEFAULT_TYPE.complaint || isWarrantyWithParent;

  return (
    <Stack spacing={3}>
      {orderSession && orderSession.contracts.length > 0 && (
        <OrderSessionContractDataTableBlock orderSession={orderSession} formState={formState} />
      )}
      {orderSession && (
        <OrderTablesListBlock orderId={orderSession.id} orderUpdatedAt={orderSession.updatedAt} tables={orderSession.tables || []} />
      )}

      <FormProvider formState={formState} sx={{ maxWidth: '800px' }}>
        <Typography variant="h6">{translate('pages.orderSessionEdit.otherData')}</Typography>
        <AutocompleteMemorized
          fieldName="type"
          label={translate('entities.orderType.entityName')}
          formState={formState}
          options={allowedOrderType}
          isLoading={orderTypeAllAreLoading}
          disabled={disableType}
        />
        <AutocompleteMemorized
          fieldName="legalEntity"
          label={translate('entities.legalEntity.entityName')}
          formState={formState}
          options={legalEntityAll}
          isLoading={legalEntityAllAreLoading}
        />
        <AutocompleteMemorized

          fieldName="region"
          label={translate('entities.region.entityName')}
          formState={formState}
          options={regionAll}
          isLoading={regionAllAreLoading}
          startAdornment={<OrderRegionIcon />}
        />
        <AutocompleteMemorized
          fieldName="brand"
          label={translate('entities.brand.entityName')}
          formState={formState}
          options={brandAll}
          isLoading={brandAllAreLoading}
        />
        <AutocompleteMemorized
          fieldName="shop"
          label={translate('entities.shop.entityName')}
          formState={formState}
          options={shopAll}
          isLoading={shopAllAreLoading}
        />


        <fieldset style={{ border: 'none' }} disabled={formState.isSubmitting}>
          <Stack spacing={3}>
            {dynamicFieldsConfigs
              .filter((field) => shownCustomFieldIds.includes(field.id))
              .map((field) => (
                <DynamicFieldEditState key={field.id} field={field} formState={formState} isAddForm />
              ))}
          </Stack>
        </fieldset>

        <TextField
          label={translate('pages.orderSessionEdit.contractsNumber')}
          type='number'
          onChange={(e) => setContractsNumber(Number(e.target.value))}
          value={contractsNumber}
        />

        {Boolean(formState.values.apartments?.length)
          && <OrderSessionContractsBlock formState={formState} apartments={formState.values.apartments ?? []} />}

      </FormProvider>


      <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="baseline">
        <Stack direction="row" spacing={2} flex={{ xs: 1, md: 0 }}>
          <LoadingButton
            onClick={formState.submitForm}
            variant="contained"
            color="primary"
            loading={formState.isSubmitting}
            sx={{ flexGrow: { xs: 1, md: 0 } }}
          >
            {translate('buttons.next')}
          </LoadingButton>
        </Stack>

        <Button variant="outlined" color="error" onClick={() => setIsCancelBlockOpened(true)}>
          {translate('buttons.cancel')}
        </Button>
        <PopupAction
          title={translate(`pages.orderAdd.cancelSessionTitle`)}
          isOpened={isCancelBlockOpened}
          actionHandler={cancelSessionHandler}
          closeHandler={() => setIsCancelBlockOpened(false)}
        >
          <Typography variant="body2">{translate('pages.orderAdd.cancelSessionText')}</Typography>
        </PopupAction>
      </Stack>
    </Stack>
  );
}

function OrderSessionContractsBlock({ apartments, formState }: { apartments: OrderApartment[], formState: FormikContextType<any> }) {
  return <TableVariant1 header={<TableHeader />}>
    {apartments.map((apartment, index) => (
      <OrderSessionNumbersItem
        apartment={apartment}
        key={apartment.developerApartmentId + index}
        shop={formState.values.shop}
        orderType={formState.values.type}
        formState={formState}
        index={index}
      />))}

  </TableVariant1>
}


type OrderSessionNumbersItemProps = {
  shop: Shop,
  apartment: OrderApartment,
  orderType: OrderType,
  formState: FormikContextType<any>,
  index: number
}

function OrderSessionNumbersItem({
  shop,
  apartment,
  orderType,
  formState,
  index
}: OrderSessionNumbersItemProps) {
  const { storageActions } = useDI()
  const [orderNumber, setOrderNumber] = useState('')
  const [apartmentNumberState, setApartmentNumberState] = useState(apartment.apartmentNumber)
  const [developerApartmentIdState, setDeveloperApartmentIdState] = useState(apartment.developerApartmentId)

  useDebounce({
    delay: 500,
    instantRequest: true,
    dependencies: [apartmentNumberState, developerApartmentIdState, shop, orderType],
    action: async () => {
      formState.setFieldValue(`apartments[${index}]`, { apartmentNumber: apartmentNumberState, developerApartmentId: developerApartmentIdState })
      if (!shop || !orderType || !apartmentNumberState.length || !developerApartmentIdState.length) return
      const orderNumber = await storageActions.models.orderSession.generateOrderNumber({
        apartmentNumber: apartmentNumberState,
        developerApartmentId: developerApartmentIdState,
        dealerNumber: shop.dealerNumber,
        shopNumber: shop.shopNumber,
        orderType: orderType.name
      })
      setOrderNumber(orderNumber)
    }
  })

  return <TableRow>
    <TableCell>{orderNumber}</TableCell>
    <TableCell>{shop?.dealerNumber ?? ''}</TableCell>
    <TableCell>{shop?.shopNumber ?? ''}</TableCell>
    <TableCell sx={{ width: '20%', background: '#E9FCD4' }}>
      <TextField
        sx={{
          maxWidth: '140px',
          '& .MuiOutlinedInput-root': {
            fontSize: { xs: '12px', md: '14px' },
            '& fieldset': {
              border: 'none',
            },
          },
        }}
        onChange={(e) => setApartmentNumberState(e.target.value)}
        value={apartmentNumberState}
      />
    </TableCell>
    <TableCell sx={{ width: '20%', background: '#E9FCD4' }}>
      <TextField
        sx={{
          maxWidth: '140px',
          '& .MuiOutlinedInput-root': {
            fontSize: { xs: '12px', md: '14px' },
            '& fieldset': {
              border: 'none',
            },
          },
        }}
        onChange={(e) => setDeveloperApartmentIdState(e.target.value)}
        value={developerApartmentIdState}
      />
    </TableCell>
  </TableRow>
}

function TableHeader() {
  const { translate } = useLocales()
  return <>
    <colgroup>
      <col style={{ width: '20%' }} span={5} />
    </colgroup>
    <TableRow>
      <TableCell>{translate('pages.orderAdd.finalNumber')}</TableCell>
      <TableCell>{translate('pages.orderAdd.dealerNumber')}</TableCell>
      <TableCell>{translate('pages.orderAdd.buildingCaseNumber')}</TableCell>
      <TableCell>{translate('pages.orderAdd.flatNumber')}</TableCell>
      <TableCell>{translate('pages.orderAdd.flatCode')}</TableCell>
    </TableRow>
  </>
}

export default React.memo(
  OrderAddMultipleFromSessionForm,
  (pp, np) => pp.orderSession?.updatedAt.getTime() === np.orderSession?.updatedAt.getTime()
);
